$red: #ff385c;
$blue: rgb(115, 7, 202);
@import './scss/mixing';
body{  
  background-color: rgb(243, 243, 243);
  @include screen-sm{
    background: url(./background.svg) no-repeat top right; 
    background-size: auto 40vw;   
  }
  font-size: 16px;
  overflow-x: hidden;
  *{
    font-family: 'Titillium Web', sans-serif;
  }
  .container{
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 24px;
    overflow-x: hidden;
    @include screen-sm{
      padding: 0 32px;
    }
    @include screen-lg{
      padding: 0 64px;
    }
  }
  a{
    text-decoration: none;
  }
  header{
    padding-bottom: 8px;
    text-align: center;
    .container{
      position: relative;
      overflow-y: hidden;
    }    
    nav{
      margin-top: 16px;
      font-family: 'Indie Flower', cursive;
      font-size: 14px;
      @include screen-sm{
        position: absolute;
        bottom: 0;
        right: 24px;
        font-size: 16px;
        right: 64px;
      }
      a{
        margin-right: 12px;
        @include screen-sm{
          color: #fff;
        }
      }
    }
    .button{
      display: none;
    }
  }
  .logo{
    display: block;
    margin-bottom: 16px;
    max-width: 208px;
    line-height: 1;
    margin: 8px auto 0;
    @include screen-sm{
      max-width: 304px;
      font-size: 40px;
      margin-left: 0;
      text-align: left;
    }
    .brand{
      display: block;
      font-family: 'Monoton', cursive;
      font-size: 28px;
      @include screen-sm{
        font-size: 40px;
      }
    }
    a.social{
      display: block; 
      padding: 0;
      margin: 0;
      list-style-type: none;
      font-size: 12px;
      i{
        font-size: 1.2em;
      }
    }
  }
  .no-available-msg{
    display: block;
    background-color: $red;
    color: #fff;
    padding: 8px;
  }
  #contact {
    padding: 40px 0;
    text-align: center;
    img {
      display: block;
      width: 80px;
      margin: 0 auto;
    }
  }
  .button{
    border: none;
    background-color: #dc0e63;
    color: #fff;
    padding: 16px 32px;
    border-radius: 8px;
    width: 100%;
    font-weight: bold;
    font-size: 18px;
    cursor: pointer ;
  }
  .highlight{
    background-color: rgba(252, 227, 85, 0.5);
  }
  p{
    margin: 0 0 16px;
  }
  .booking{
    padding: 0 0 24px;
    line-height: 1.2;
    min-height: 800px;
    @include screen-sm{
      background-color: transparent;
    }
    .container{
      overflow: visible;
      .user{
        display: block;
        position: absolute;
        right: 0;
        top: 24px;
        border: none;
        font-size: 16px;
        background-color: transparent;
        cursor: pointer;
        @include screen-sm{
          right: 8px;
        }
        span{
          display: block;
          position: absolute;
          right: 0;
          top: 12px;
          padding: 0 4px;
          width: 16px;
          height: 24px;
          border-radius: 100%;
          background-color: $red;
          color: #fff;
          font-size: 14px;
        }
        i{
          font-size: 24px;
          color: $blue;
        }
      }
      .inner{
        position: relative;
        @include screen-sm{
          padding-left: 200px;
        }
      }
      .headline{
        text-align: center;
        @include screen-sm{
          color: #fff;
        }
        h1{
          font-size: 24px;
          margin-bottom: 8px;
          line-height: 1;
          @include screen-sm{
            font-size: 32px;
            text-shadow: 0 0 4px #000;
          }
        }
        p{
          font-size: 16px;
          @include screen-sm{
            font-size: 18px;
            text-shadow: 0 0 4px #000;
          }
        }
        @include screen-sm{
          position: absolute;
          bottom: 32px;
          right: 24px;
          width: 480px;
          background: rgba(100, 78, 238, 0.5);
          padding: 0 24px;
          border-radius: 32px;
        }
      }
    }
    img{
      width: 100%;
      height: auto;
      border-radius: 8px;
      @include screen-sm{
        min-height: 774px;
        width: auto;
      }
    }
    .react-calendar{
      border: none;
    }
    .calendar, .history{
      margin: 0 -24px;
      padding: 24px;
      background-color: #fff;
      border-top: 8px solid #dc0e63; 
      overflow: hidden;
      z-index: 9;
      i{
        color: $red;
      }
      &.busy{
        pointer-events: none;
        &:before{
          display: block;
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: rgba(#fff, 0.8);
          z-index: 999;
        }
        @keyframes spinner {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      }
      &.confirm{
        border-top: 8px solid rgb(69, 188, 201); 
      }
      @include screen-sm{
        position: absolute;
        top: 40px;
        left: 0;
        margin: 0;
        box-shadow: 0 0 12px rgba(#333, .4);
        border-radius: 8px;
        max-width: 420px; 
      }
      @include screen-lg{
        padding: 32px;
      }
      h1, h2, h3{
        margin: 0 0 16px;
      }
      .react-calendar{
        width: 100%;
        .react-calendar__navigation__label{
          pointer-events: none;
        }
        .react-calendar__navigation__arrow{
          font-size: 32px;
        }
        .react-calendar__navigation__next2-button, .react-calendar__navigation__prev2-button{
          display: none;
        }
      }
      .btnBack{
        display: block;
        position: absolute;
        top: 6px;
        right: 6px;
        border: none;
        background-color: transparent;
        color: $blue;
        padding: 4px 8px;
        border-radius: 8px;
        cursor: pointer;
      }
      .time-slide{
        .timeslot{
          position: relative;
          .duration{
            font-weight: bold;
            font-size: 14px;
            text-align: center;
          }
          .btnClear{
            position: absolute;
            top: 0;
            right: 0;
            border: none;
            font-weight: bold;
            text-decoration: underline; 
            background-color: #fff;
            cursor: pointer;
          }          
        }
        .times{
          display: flex;
          justify-content: flex-start;
          flex-flow: row wrap;
          margin: 16px auto;
          gap: 8px;
          list-style-type: none;
          &.invalid{
            button:not(.booked):not(.passed){
              border: 1px solid $red;
            }
          }
          @include screen-sm{
            margin-left: 0;
          }
          button{
            flex: 0 calc(33.3333% - 12px);
            display: block;
            width: 100%;
            background-color: #fff;            
            border-radius: 24px;        
            padding: 6px 4px;   
            border: none;
            cursor: pointer;
            font-size: 14px;
            @include screen-sm{
              flex: 0 calc(25% - 12px);
            }
            &.selected{
              position: relative;
              display: block;
              background-color: rgb(41, 38, 38);                
              color: #fff;  
              &:hover{
                background-color: rgb(255, 107, 107);
              }
              &::before{
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: -30%;
                right: 50%;
                background-color: rgb(187, 187, 187);
                z-index: -1;
              } 
              &.start{
                z-index: 2;
                &::before{
                  display: none;
                }
              }
              &.middle{
                background-color: rgb(187, 187, 187);
                color: #333;
                z-index: 1;
              }
            }                         
            &.passed{
              position: relative;
              pointer-events: none;
              box-shadow: none;
              background-color: rgb(250, 222, 245);                
              color: rgb(193, 193, 193);              
            }
            &.booked{
              position: relative;
              pointer-events: none;
              background-color: rgb(255, 202, 244);
              color: rgb(185, 185, 185);
              box-shadow: none;
              &::after{
                content: 'No';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) rotate(40deg);
                color: rgb(255, 0, 0);
                font-size: 14px;
              }    
            }                  
          }
        }
        .services{
          display: flex;
          justify-content: space-between;
          flex-flow: row wrap;
          margin-bottom: 32px;
          &.invalid{
            .service{
              &::before{
                border-color: #dc0e63;
              }
            }
          }
          .service{
            display: block;
            flex: 0 100%;
            position: relative;
            padding-left: 28px;
            cursor: pointer;
            margin: 8px 0;
            @include screen-sm{
              flex: 0 calc(50% - 34px);
            }
            small{
              color: $red;
            }
            &::before{
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              width: 20px;
              height: 20px;
              border-radius: 8px;
              border: 1px solid #5d79ff;
            }
            &.checked{
              &::after{
                content: '\f00c';
                font-family: FontAwesome;
                position: absolute;
                display: block;
                top: 12px;
                left: 2px;
                font-size: 24px;
                line-height: 0;
              }
            }
          }
        }        
      }
      .location-slide{
        .field{
          margin: 16px 0;
          &.invalid{
            input[type=text], input[type="tel"]{
              border-color: $red;
            }
          }
          label{
            display: block;
          }
          i{
            color: $red;
          }
          input[type=text], input[type="tel"], textarea{
            display: block;
            width: 100%;
            padding: 12px;
            box-sizing: border-box;
            border: 1px solid #aeb8e8;
            border-radius: 4px;
            outline: none;
          }
          .PhoneInputCountry{
            display: none;
          }
          &.radio{
            label{
              display: inline-block;
              position: relative;
              padding-left: 28px;
              cursor: pointer;
              margin-right: 12px;
              &::before{
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 20px;
                height: 20px;
                border-radius: 100%;
                border: 1px solid #5d79ff;
              }
              &.checked{
                &::after{
                  content: '';
                  position: absolute;
                  display: block;
                  top: 6px;
                  left: 5px;
                  width: 12px;
                  height: 12px;
                  border-radius: 100%;
                  background-color: #5d79ff;
                }
              }
            }
          }
        }
        .btnNotYou{
          background-color: transparent;
          border: none;
          color: $blue;
          font-style: italic;
          cursor: pointer;
          font-weight: bold;
        }
      }
      .error-msg{
        position: relative;
        background-color: rgba($red, .2);
        padding: 8px;
        margin-bottom: 8px;
        padding-left: 32px;
        border-radius: 16px;
        &::before{
          content: '\f071';
          display: block;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 8px;
          font-family: FontAwesome;
          color: rgb(235, 1, 1);
        }
        span{
          display: block;
          font-size: 14px;
          color: rgb(0, 0, 0);
        }
      }
      .slides{
        position: relative;
        overflow: hidden;
        .slide{
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          transform: translateX(-100%);
          transition: all 300ms ease;
          &.active{   
            position: relative;         
            transform: translateX(0);
            & ~ .slide{
              transform: translateX(100%);
            }
          }
        }
      }
    }
    .confirm-box{
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(#333, .8);
      overflow-y: auto;
      z-index: 999;
      @include screen-sm{
        position: absolute;
        background-color: #fff;
      }
      .modal-body{
        padding: 32px;
        background-color: #fff;
        .address-confirm{
          background-color: rgb(255, 239, 146);
          text-align: center;
          padding: 8px;
          color: $red;
        }
        .shoutout{
          img{
            display: block;
            float: left;
            width: 56px;
            height: 56px;
            margin: 0 8px 8px 0;
            min-height: auto;
          }
        }
      }
      .chq-atc--button{
        color: #000;
        margin-bottom: 16px;
      }
      .chq-atc--dropdown{
        margin-top: -16px;
      }
    }
    .history{
      padding-top: 32px;
      @include screen-sm{
        min-width: 320px;
      }
      .close-history{
        position: absolute;
        display: block;
        top: 24px;
        right: 16px;
        border: none; 
        cursor: pointer;
        font-size: 18px;
      }
    }
  }
  .flexslider{
    .btn{
      display: inline-block;
      font-size: 16px;
      background-color: $blue;
      color: #fff;
      border: none;
      border-radius: 8px;
      padding: 4px 8px;
      margin-right: 16px;
      width: 120px;
      cursor: pointer;
      &.disable{
        pointer-events: none;
        background-color: #ccc;
      }
      &.btnNex{
        float: right;
      }
    }
    .slides{
      padding: 0;
      margin: 24px 0;
      list-style-type: none;
      li{
        display: block;
        text-align: center;
        margin-bottom: 16px;
        padding-bottom: 24px;
        border-bottom: 1px solid rgb(236, 41, 210);
        &:last-child{
          border: none;
        }
        img{
          display: inline-block;
          width: calc(50% - 8px);
          max-width: 200px;
          vertical-align: middle;
          margin: 4px;
          border-radius: 8px;
          @include screen-sm{
            max-width: 250px;
          }
        }
      }
    }
  }
  h1, h2, h3{
    font-family: 'Titillium Web', cursive;
  }
  section:not(.booking){
    h2{
      text-align: center;
      font-size: 32px;       
    }
  }
  .pricing{
    position: relative;
    background: $red url(./pricing-min.png) no-repeat bottom right;
    color: #fff;
    border-radius: 0 0 50% 50%/0 0 80px 80px;
    padding-bottom: 80px;
    background-size: 140px;
    z-index: 2;
    @include screen-sm{
      padding-top: 64px;
      padding-bottom: 120px;
      background-size: contain;
    }
    @include screen-lg{
      background-size: auto;
    }
    .container{
      table{
        width: 90%;
        @include screen-sm{
          width: 70%;
        }
      }
      th{
        text-align: left;
        padding: 8px 16px;
      }
      td{
        border-bottom: 1px solid rgb(179, 179, 179);
        padding: 8px 16px;
        line-height: 1;
        &:first-child{
          width: 70%;
        }
      }
    }
  }
  .covid{
    position: relative;
    z-index: 1;
    text-align: center;
    border-radius: 0 0 50% 50%/0 0 80px 80px;
    background-color: rgb(196, 241, 252);
    margin-top: -80px;
    padding-top: 80px;
    padding-bottom: 64px;
    .container{
      max-width: 600px;
    }
    p{
      font-size: 18px;
    }
  }
  .feature-wrapper{
    margin-bottom: 40px;
    img{
      width: 100%;
      display: block;
      margin: 0 auto;
      max-width: 800px;
    }
  }
  #portfolio{
    position: relative;
    background-color: #f7f7f7;
    padding-bottom: 64px;
    .btn-more{
      position: absolute;
      display: block;
      bottom: 30px;
      left: 50%;
      transform: translateX(-50%);
      background-color: transparent;
      border: none;
      line-height: .8;
      color: $blue;
      font-weight: bold;
      font-size: 18px;
      cursor: pointer;
      &.busy{
        pointer-events: none;
        opacity: 0.8;
        &::after{
          content: '\f110'; 
          color: rgb(102, 102, 102);
          animation: spinning 1s infinite linear;
        }
        @keyframes spinning {
          0%{transform: rotate(0deg);}
          100%{transform: rotate(359deg);}
        }
      }
      &:after{
        display: block;
        content: '\f107';
        font-family: 'FontAwesome';
        font-size: 18px;
      }
    }
  }
  .reviews{
    position: relative;
    padding: 40px 0 80px;    
    h2{
      text-align: center;
      font-size: 20px !important;
    }

    ul{
      display: flex;
      justify-content: center;
      flex-flow: row wrap;
      gap: 16px;
      padding: 0;
      margin: 0;
      list-style-type: none;
      @include screen-sm{
        gap: 24px;
      }
      li{
        flex: 0 100%;
        position: relative;
        border-left: 1px solid $red;
        border-right: 1px solid $red;
        border-top: 1px solid $red;
        padding-bottom: 40px;
        border-radius: 40px 0 20px 0;
        @include screen-sm{
          flex: 0 calc(50% - 16px);
        }
        @include screen-lg{
          flex: 0 calc(33.3333% - 80px);
        }
        &:nth-child(n+3){
          display: none;
        }
        i{
          display: block;
          position: absolute;
          top: 0;
          right: 16px;
          font-size: 40px;
          line-height: 0.5;
        }
        p{
          font-size: 20px;
          padding: 24px;
        }
        .author{
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          font-weight: bold;
          background-color: $red;
          color: #fff;
          padding: 8px;
          border-radius: 0 0 20px 0;
        }
      }
    }
    &.show-more{
      ul li:nth-child(n+3){
        display: block;
      }
      .btn-more{
        &:after{
          display: none;
        }
        &::before{
          display: block;
          content: '\f106';
          font-family: 'FontAwesome';
          font-size: 18px;
        }
      }
    }
    .btn-more{
      position: absolute;
      display: block;
      bottom: 30px;
      left: 50%;
      transform: translateX(-50%);
      background-color: transparent;
      border: none;
      line-height: .8;
      color: $blue;
      font-weight: bold;
      cursor: pointer;
      &:after{
        display: block;
        content: '\f107';
        font-family: 'FontAwesome';
        font-size: 18px;
      }
    }
  }
  #location{
    padding: 40px 0;
    background-color: #2d8d85;
    color: #fff;
    text-align: center;
    .container{
      overflow: hidden;
    }
    a{
      color: #fff;
      font-weight: 900;
      text-decoration: underline;
    }
    .row{
      display: flex;
      justify-content: space-between;
      flex-flow: row wrap;
      .col{
        flex: 0 100%;
        @include screen-sm{
          flex: 0 calc(50% - 16px);
        }
      }
    }
    img{
      max-width: 100%;
    }
    iframe{
      width: 100%;
      border: none;
      height: 100%;
      min-height: 300px;
      @include screen-sm{
        min-height: 100%;
      }
    }
  }
  .modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0, .9);
    z-index: 9;
    
    .container {
      position: relative;
      height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;
      touch-action: pan-x pinch-zoom; // Enable horizontal swipe
    }
  
    img {
      display: block;
      width: 100%;
      max-width: 1000px;
      margin: 48px auto 0;
      object-fit: contain;
      
      @include screen-sm {
        width: auto;
        max-height: calc(100vh - 100px);
        margin: 0 auto;
      }
    }
  
    .closeModal {
      position: absolute;
      top: 20px;
      right: 20px;
      background-color: transparent;
      color: #fff;
      font-size: 24px;
      border: none;
      cursor: pointer;
      z-index: 10;
    }
  
    // Add navigation buttons
    .nav-btn {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background: rgba(255, 255, 255, 0.2);
      border: none;
      color: #fff;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      cursor: pointer;
      z-index: 10;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: background 0.3s;
  
      &:hover {
        background: rgba(255, 255, 255, 0.3);
      }
  
      &.prev {
        left: 20px;
      }
  
      &.next {
        right: 20px;
      }
  
      i {
        font-size: 20px;
      }
  
      @include screen-sm {
        width: 50px;
        height: 50px;
        i {
          font-size: 24px;
        }
      }
  
      // Hide on mobile - use swipe instead
      @media (max-width: 768px) {
        display: none;
      }
    }
  
    .image-counter {
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
      color: #fff;
      background: rgba(0, 0, 0, 0.5);
      padding: 5px 15px;
      border-radius: 15px;
      font-size: 14px;
    }
  }
  .bio{
    padding: 40px 0;
    color: #fff;
    background: $blue url(./45-degree-fabric-light.png);
    .container{
      position: relative;
      max-width: 604px;
    }    
    img{
      display: block;
      margin: 24px auto;
      border-radius: 100%;
      width: 120px;
      height: 120px;
      @include screen-sm{
        float: left;
        margin: 0 24px 24px 0;
      }
    }
  }
  footer{
    .container{
      padding-bottom: 24px;
      .complaint{
        margin-top: 40px;
        text-align: center;
        font-size: 14px;
      }
      p{
        padding-top: 24px;
      }
    }
  }
  #btnBook{
    position: fixed;
    display: none;
    top: 0;
    left: 0;
    background-color: #dc0e63;
    color: #fff;
    font-weight: bold;
    padding: 8px 16px;
    border-radius: 0 0 18px 0;  
    width: 116px;
    z-index: 5;
    &.active{
      display: block;
    }  
  }
}
.underline{
  text-decoration: underline;
}
.dashboard{
  background-color: #fff;
  .review{
    width: 50%;
    input[type=text], textarea{
      width: 100%;
    }
    .button{
      max-width: 240px;
    }
  }
  .log{
    padding: 40px 0;
    display: flex;
    justify-content: space-between;
    flex: row wrap;
    gap: 16px;
    #clients{
      max-height: 300px;
      flex: 0 calc(50% - 8px);
      overflow-y: auto;
      padding: 16px;
      margin: 0;
      list-style-type: none;
      border: 1px solid $blue;
      border-radius: 8px;
      box-sizing: border-box;
      li{
        cursor: pointer;
      }
    }
    .log-area{
      flex: 0 calc(50% - 8px); 
      textarea, .text{
        box-sizing: border-box;
        width: 100%;
        min-height: 200px;
        padding: 8px 16px;
        border-radius: 8px;  
        border: 1px solid $blue;
      }
    }
  }
}
.Privacy{
  background-color: #fff;
}